<template>
  <div>
    <breadcrumbs
      :breadcrumb-items="breadcrumbItems"
    />
    <b-card>
      <div class="head-card">
        <div class="head-card-top">
          <h4>{{ $t('decode-url.title') }}</h4>
          <feather-icon
            v-b-tooltip.hover.top="$t('Close')"
            icon="XIcon"
            size="18"
            class="toggle-close cursor-pointer"
            @click="$router.go(-1)"
          />
        </div>
        <hr>
      </div>
      <b-form-group
        label-for="i-checking"
      >
        <b-input-group class="input-group-merge">
          <b-input-group-prepend is-text>
            <feather-icon icon="Link2Icon" />
          </b-input-group-prepend>
          <b-form-textarea
            id="i-checking"
            v-model="querystring"
            rows="5"
            :placeholder="$t('decode-url.enterUrl')"
            autocomplete="checking"
          />
        </b-input-group>
      </b-form-group>

      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        @click="decoderUrl"
      >
        <span class="align-middle">{{ $t('decode-url.decode') }}</span>
        <feather-icon
          icon="ChevronRightIcon"
          class="ml-50"
        />
      </b-button>

      <template
        v-if="Object.keys(decodeResult).length > 0"
      >
        <div class="ibox mt-2 success">
          <div class="header">
            <feather-icon
              icon="AlertCircleIcon"
              width="24"
              height="24"
            />
            <strong class="mr-auto">Success</strong>
          </div>
          <div class="body">
            <dl
              v-for="(result, idx) in Object.keys(decodeResult)"
              :key="idx"
            >
              <dt><strong>{{ result }}:</strong></dt> <dd>{{ decodeResult[result] }}</dd>
            </dl>
          </div>
        </div>
      </template>
      <template
        v-if="emptyQueryString"
      >
        <div class="ibox mt-2 danger">
          <div class="header">
            <feather-icon
              icon="AlertCircleIcon"
              width="24"
              height="24"
            />
            <strong class="mr-auto">Error</strong>
          </div>
          <div class="body">
            {{ $t('decode-url.emptyQuery') }}
          </div>
        </div>
      </template>
    </b-card>
  </div>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import Ripple from 'vue-ripple-directive'
import Breadcrumbs from '@/views/ui/breadcrumbs/Breadcrumbs.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import {
  BCard,
  BButton,
  BFormGroup,
  BInputGroup,
  BInputGroupPrepend,
  BFormTextarea,
  VBTooltip,
} from 'bootstrap-vue'

export default {
  name: 'DecodeUrl',
  components: {
    BCard,
    Breadcrumbs,
    BButton,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      querystring: '',
      emptyQueryString: false,
      breadcrumbItems: [
        {
          text: 'decode-url.title',
          active: true,
        },
      ],
      decodeResult: {},
    }
  },
  methods: {
    async decoderUrl() {
      this.decodeResult = {}
      this.emptyQueryString = false
      if (this.querystring === '') {
        this.emptyQueryString = true
        return
      }
      await useJwt.getDbd({ querystring: this.querystring })
        .then(response => {
          /* this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'ListIcon',
              variant: 'success',
            },
          }) */
          this.decodeResult = response.data.data
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.message,
              icon: 'EditIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>
